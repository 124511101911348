<template>
  <div :class="`${prefixCls}-work`" id="aochenWork">
    <Separate title="党建工作" desc="PARTY BUILDING WORK" class="wow animate__fadeInUp" data-wow-duration="1s" />
    <div
        :class="`${prefixCls}-work-content wow animate__fadeInUp`"
        data-wow-duration="2s"
        data-wow-delay="0.5s"
    >
      <custom-card class="card" v-for="item in workList" :key="item.id" :value="item" @click="handleClick" />
    </div>
  </div>
</template>

<script>
import Separate from '../../../components/Separate/index'
import { WowMixin } from '@/mixin/wow.js'
import CustomCard from '@/components/CustomCard/index.vue'
import { siteNewsPage } from '@/api/index'
export default {
  name: 'CompanyDynamics',
  data () {
    return {
      prefixCls: this.$prefix + '-home',
      workList: []
    }
  },
  mixins: [WowMixin],
  props: {
    introductionData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    CustomCard,
    Separate
  },
  created () {
    this.siteNewsPage()
  },
  methods: {
    handleClick (data) {
      this.$router.push({ path: '/detail', query: { id: data.id } })
    },
    siteNewsPage () {
      siteNewsPage(
        {
          channelId: '28'
        },
        {
          channelId: 0
        }
      ).then(({ data }) => {
        this.workList = data.data.rows
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -home-work;
.#{$prefixCls}{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: $content-width;
  &-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .card {
      width: 380px;
      margin: 0 25px 25px 0;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>
